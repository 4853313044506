<template>
  <div>

  </div>
</template>

<script>
import qs from "qs";

export default {
  name: "tiyanVip",
  mounted() {
    let from = "ldy"
    if (window.location.href.indexOf("from") != -1){
      let id = qs.parse(window.location.href.split("#")[0].split("?")[1])
      from = id.from;
      //console.log("from",from)
    }
    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx73d999be9bec0d08&redirect_uri=https%3A%2F%2Fwx.sixu.work%2Fjxpay%3Ffrom%3D"+from+"%26isTiYan%3D1&response_type=code&scope=snsapi_base&state=payJx#wechat_redirect"
  },
}
</script>

<style scoped>

</style>